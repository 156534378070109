<template>
  <v-sheet
    rounded="xl"
    class="text-start pa-6 py-8 ma-4 mt-0"
    :width="$vuetify.breakpoint.mdAndUp ? '786px' : $vuetify.breakpoint.smAndUp ? '586px' : ''"
  >
    <v-sheet color="primary secondary pa-6" rounded="lg">
      <div class="d-flex justify-space-between">
        <div class="d-flex">
          <v-img :src="require(`@/assets/icons/claim-icon.svg`)" max-width="24px" contain />
          <div class="ml-2 text-medium font-weight-medium">
            {{ claimInfo.name }}
          </div>
        </div>
        <div v-if="isCompleted">
          <v-icon class="mr-2" color="success ligten-2">mdi-check-circle-outline</v-icon> Completed
        </div>
        <div v-else>
          <v-icon class="mr-2">mdi-minus</v-icon>
          Remaining ({{ claimedNumber }}/{{ winnerList.length }})
        </div>
      </div>
      <div :sizeIcon="20" class="text-caption mt-4">
        {{ claimInfo.description }}
      </div>
      <v-progress-linear
        height="2"
        color="primary"
        class="mb-6 mt-4"
        :value="(claimedNumber * 100) / (winnerList.length || 1)"
      />
      <v-row class="pr-sm-2 pl-2">
        <v-col md="3" sm="4" cols="6" class="mr-md-4 px-0">
          <div class="text-caption grey--text" :mlIcon="0">
            <div>
              Claiming day
              <div class="text-body-1 mt-1 white--text">{{ claimInfo.activeDate | ddmmyyyy }}</div>
            </div>
          </div>
        </v-col>
        <v-col md="3" sm="4" cols="6" class="mr-md-4 px-0">
          <div class="text-caption grey--text mb-1">Total amount</div>
          <div class="text-body-1">{{ claimInfo.amount }}</div>
        </v-col>
        <v-col md="3" sm="4" cols="6" class="mr-md-4 px-0">
          <div class="text-caption grey--text mb-1">Address</div>
          <div class="text-body-1">{{ winnerList.length }}</div>
        </v-col>
      </v-row>
    </v-sheet>
    <v-sheet
      rounded="lg"
      class="pa-6 text-start my-6"
      max-width="433"
      v-if="hasOnWinnerList && accountIndex >= 0 && !claimedList[accountIndex]"
    >
      <div class="d-flex mb-2">
        <v-icon class="mr-2" small>mdi-wallet</v-icon>
        <div>Your wallet</div>
      </div>
      <div>
        {{ accountIndex >= 0 && winnerList[accountIndex] }}
      </div>
      <v-progress-linear
        height="2"
        background-color="rgba(255, 255, 255, 0.06)"
        color="secondary lighten-2"
        class="mb-5 mt-4"
        value="0"
      />

      <div class="d-flex justify-space-between align-center">
        <div>
          <div class="mb-2">Claim amount</div>
          {{ (accountIndex >= 0 && amountList[accountIndex]) || 0 }} {{ token.symbol }}
        </div>
        <v-btn
          :disabled="beforeActiveDate"
          rounded
          color="primary"
          :loading="claimLoading"
          @click="handleClaim({ multiClaimHandler, accountIndex: accountIndex })"
          >Claim</v-btn
        >
      </div>
    </v-sheet>
    <v-sheet
      rounded="lg"
      class="pa-6 text-start my-6"
      v-if="hasOnWinnerList && accountIndex >= 0 && claimedList[accountIndex]"
    >
      <div
        :class="{
          'd-flex justify-space-between': $vuetify.breakpoint.mdAndUp,
          'd-flex flex-column': $vuetify.breakpoint.sm,
        }"
      >
        <div :class="$vuetify.breakpoint.mdAndDown ? 'mb-4' : ''">
          <div class="d-flex mb-2">
            <v-icon class="mr-2" small>mdi-wallet</v-icon>
            Your wallet
          </div>
          <div>
            {{ accountIndex >= 0 && winnerList[accountIndex] }}
          </div>
        </div>
        <div>
          <div class="mb-2">Claimed amount</div>
          <div>{{ (accountIndex >= 0 && amountList[accountIndex]) || 0 }} {{ token.symbol }}</div>
        </div>
      </div>
    </v-sheet>

    <div class="d-flex justify-space-between align-center">
      <div class="text-medium my-6">Claim list</div>
      <v-text-field
        placeholder="Address wallet"
        prepend-inner-icon="mdi-magnify"
        dense
        style="max-width: 300px"
        outlined
        rounded
        hide-details
        @input="searchInputChange"
      >
      </v-text-field>
    </div>
    <div>
      <v-data-table :items="winnerInfo" items-key="id" :headers="originalHeaders" :loading="loading">
        <template v-slot:[`item.stt`]="{ item }"> {{ item.index }}</template>
        <template v-slot:[`item.amount`]="{ item }">
          {{ item.amount | formatNumber(8, 1) }}
        </template>
        <template v-slot:[`item.state`]="{ item }">
          <v-icon color="success ligten-2" v-if="item.claimed || item.amount == 0">mdi-check-circle-outline</v-icon>
          <v-icon v-else>mdi-minus</v-icon>
        </template>
      </v-data-table>
    </div>
  </v-sheet>
</template>
<script>
import { mapActions, mapState, mapGetters } from 'vuex'
const originalHeaders = [
  {
    text: '-',
    align: 'left',
    sortable: false,
    value: 'stt',
  },
  {
    text: 'Wallet address',
    align: 'left',
    sortable: false,
    value: 'address',
  },
  {
    text: 'amount',
    align: 'right',
    sortable: false,
    value: 'amount',
  },
  {
    text: '-',
    align: 'right',
    sortable: false,
    value: 'state',
  },
]
export default {
  components: {
    // HoverEditCardText: () => import('@/components/base/HoverEditCardText.vue'),
  },
  data() {
    return {
      id: '',
      originalHeaders: originalHeaders,
    }
  },
  computed: {
    ...mapState('auth', ['multiClaimHandler', 'account', 'connectingWallet']),
    ...mapState('claimDetailByUser', [
      'winnerList',
      'claimedList',
      'amountList',
      'name',
      'activeDate',
      'description',
      'amount',
      'token',
      'claimLoading',
      'loading',
    ]),
    ...mapGetters('claimDetailByUser', ['claimInfo', 'isCompleted', 'claimedNumber', 'beforeActiveDate', 'winnerInfo']),
    hasOnWinnerList: function () {
      const clone = this.winnerList.map((item) => item.toLowerCase())
      const index = clone.indexOf(this.account)
      return index >= 0 && (this.claimedList[index] || this.amount > 0) ? true : false
    },
    accountIndex: function () {
      const clone = this.winnerList.map((item) => item.toLowerCase())
      const index = clone.indexOf(this.account)
      return index
    },
  },
  methods: {
    ...mapActions('claimDetailByUser', ['fetchClaimDetail', 'searchInputChange', 'handleClaim']),
  },
  async mounted() {
    this.id = this.$route.params.id
    await this.fetchClaimDetail({ multiClaimHandler: this.multiClaimHandler, id: this.id, router: this.$router })
    if (this.token && this.token.symbol) {
      this.originalHeaders[2].text = `Amount (${this.token.symbol})`
    }
  },
}
</script>
<style lang="scss">
tbody {
  tr {
    background-color: transparent !important;
  }
  tr:hover {
    box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.01);
  }
  cursor: pointer;
}
</style>
