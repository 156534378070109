import { render, staticRenderFns } from "./ClaimDetailByUser.vue?vue&type=template&id=5408f86b&"
import script from "./ClaimDetailByUser.vue?vue&type=script&lang=js&"
export * from "./ClaimDetailByUser.vue?vue&type=script&lang=js&"
import style0 from "./ClaimDetailByUser.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports